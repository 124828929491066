import React from "react";

import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";

import MilestoneInitial from "../images/milestone-initial.svg";

export const CallExpertModalBody: React.FC = () => {
  return (
    <Box gap={4} display="flex" flexDirection="column">
      <Heading size="lg" variant="modalBodyHeader">
        Call an expert
      </Heading>
      <Flex justifyContent="center" alignItems="center" width="100%" pb={4}>
        <Image
          src={MilestoneInitial}
          alt="Expert Assist image"
          width={95}
          height={152}
        />
      </Flex>
      <Text>
        To get live help from an expert, give us a call at{" "}
        <b>(619) 393 - 5971</b>. We’re available to take your call Monday –
        Friday, 10am – 5pm ET.
      </Text>
    </Box>
  );
};

export default CallExpertModalBody;
